import React from 'react'

import './Header.scss'
import './Presentation.css'
import DiscordButton from "../assets/discordbutton.png";
import Samourai from "../assets/samurainew.webp";
import "aos/dist/aos.css";

const paragraph = [
    {
        title: <p className='text-paragraph2' style={{color: '#626161', fontFamily: 'Brandon', fontSize: '22px', margin: '0'}}>TRILLIONAIRE THUGS</p>,
        content: <p className="text-paragraph2" style={{maxWidth: '500px', paddingTop: '20px', fontFamily: 'Din'}}>The Trillionaire Thugs’ collection <b style={{color: "#f7c02e"}}>brings the hip hop and urban scene to the NFT market</b>. This universe is not very well represented even though it is an important part of the hype and is in line with the times. Many hip hop characters embody <b style={{color: "#f7c02e"}}>success and achievement</b> nowadays. Our characters are thugs who evolve in a hype universe and who have been digitized by talented artists to give them life in the Metaverse. By joining the community, you may be making the best investment of your life. Be part of this new financial era where being cool and rich is more compatible than ever!</p>,
    },
    {
        title: <p className='text-paragraph2' style={{color: '#626161', fontFamily: 'Brandon', fontSize: '22px', margin: '0'}}>BE PART OF THE GANG!</p>,
        content: <p className="text-paragraph2" style={{maxWidth: '500px', paddingTop: '20px', fontFamily: 'Din'}}>Your NFT is both an <b style={{color: "#f7c02e"}}>investment</b> and a key that can open the doors to a <b style={{color: "#f7c02e"}}>premium community</b>, which is called <b style={{color: "#f7c02e"}}>Trillionaire Thugs’ Gang</b> and that will give you access to many <b style={{color: "#f7c02e"}}>exciting rewards and awesome events</b>! Owning a Trillionaire Thug NFT means that, more than just owning a piece of art, you are <b style={{color: "#f7c02e"}}>part of a gang</b> which grants you access to various benefits that will grow over time such as Merch and a free NFT collection drop. If you want to stay up to date and know everthing, about the <b style={{color: "#f7c02e"}}>upcoming events </b>that will happen for the community as well as the <b style={{color: "#f7c02e"}}>progress of our different projects</b>, follow us on our social media where you will be able to check our  <b style={{color: "#f7c02e"}}>roadmap</b>!</p>,
    },
    {
        title: <p className='text-paragraph2' style={{color: '#626161', fontFamily: 'Brandon', fontSize: '22px', margin: '0'}}>RARE & UNIQUES</p>,
        content: <p className="text-paragraph2" style={{paddingTop: '20px', fontFamily: 'Din'}}>Have you ever seen a gang without a boss? In any gang, you get respect and the benefits that go with it, by climbing the ladder. On mint day, you have a direct chance to <b style={{color: "#f7c02e"}}>become a Gold member without having to start from the bottom</b>. On mint day, you will have 5% chance to mint a Golden Thug, as <b style={{color: "#f7c02e"}}>390 pieces are randomly Golden!</b> Their owners will receive additional benefits when the NFTs are connected to the Metaverse. <b style={{color: "#f7c02e"}}>Just by holding you will get a reward from the crew!</b> In addition to that, 10 Legendary NFTs that will make reference to the crypto world will complete the collection.</p>,
    },
    {
        title: <p className='text-paragraph2' style={{color: '#626161', fontFamily: 'Brandon', fontSize: '22px', margin: '0'}}>PLAY 2 EARN GAME</p>,
        content: <p className="text-paragraph2" style={{paddingTop: '20px', fontFamily: 'Din'}}>The development team is currently working on a <b style={{color: "#f7c02e"}}>game Play2Earn</b> in collaboration with an <b style={{color: "#f7c02e"}}>international publisher and a L2 ETH blockchain.</b>Trillionaire Thugs will create its <b style={{color: "#f7c02e"}}>own Token</b> this year and the holders of the initial collection will get an <b style={{color: "#f7c02e"}}>airdrop of this coin </b>(depending on the duration of their hold and the number of holders). This Play2Earn game will be accessible to everyone and will allow players to <b style={{color: "#f7c02e"}}>enhance their gaming experience</b> thanks to their <b style={{color: "#f7c02e"}}>NFTs</b>. On top of that, the gangsta thing is that players will be able to <b style={{color: "#f7c02e"}}>earn coins while playing!</b> Holders of the first collection will get a free <b style={{color: "#f7c02e"}}>airdrop</b> from a NFT collection associated with the game, on the new blockchain. We expect the game to be released for the  <b style={{color: "#f7c02e"}}>end of 2022</b>, meanwhile be ready for big announcements soon!</p>,
    },
]
const Presentation = (props) => {
    // useEffect(() => {
    //     AOS.init({ duration: 1000, once: false });
    // }, [])
    return (
        
        <div data-aos="fade-up" data-aos-offset="150">
            { props.int == 0 ? 
            <>
                <div className="bar-mid-top"></div>
                <div className="bar-title2">
                    <p className="text-title inside" style={{color: '#d4c5a8'}}>THE PROJECT</p>
                </div>
                <div className="bar-mid" style={{height: '620px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', maxWidth: '1150px'}}>
                        <div className="decimal-text inside"></div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {paragraph[props.int].title}
                            <div style={{display: 'inline-flex'}}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {paragraph[props.int].content}
                                    <a style={{width: "100%"}} className="text-paragraph2" href="https://discord.gg/trillionairethugs" target="_blank" rel="noreferrer">
                                        <img src={DiscordButton} alt="Discord"/>
                                    </a>
                                </div>
                                <div className="card-to-rotate"></div>
                                <div className="card-to-rotate2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            : props.int == 1 ?
                <>
                    <div className="bar-mid-top"></div>
                    <div className="bar-title2">
                        <p className="text-title inside" style={{color: '#d4c5a8'}}>THE COMMUNITY</p>
                    </div>
                    <div className="bar-mid" style={{height: '620px'}}>
                        <div style={{display: 'flex', flexDirection: 'row-reverse', maxWidth: '1150px', margin: 'auto', justifyContent: "space-between"}}>
                            <div className="decimal-text "></div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {paragraph[props.int].title}
                                <div style={{display: 'inline-flex'}}>
                                    {paragraph[props.int].content}
                                </div>
                            </div>
                            <img src={Samourai} width="45%"/>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="bar-mid-top"></div>
                    <div className="bar-title2">
                        <p className="text-title inside" style={{color: '#d4c5a8'}}>THE COLLECTION AND THE GAME</p>
                    </div>
                    <div className="bar-mid" style={{height: '620px'}}>
                        <div style={{display: 'flex', flexDirection: 'row', maxWidth: '1150px', margin: 'auto'}}>
                            <div className="decimal-text "></div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {paragraph[props.int].title}
                                {paragraph[props.int].content}
                                {paragraph[props.int+1].title}
                                {paragraph[props.int+1].content}
                            </div>
                            {/* <img src={Samourai}/> */}
                        </div>
                    </div>
                </>
            }
            <div className="bar-bottom"></div>
        </div>
    )
}

export default Presentation
