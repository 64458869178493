import React, {useEffect} from 'react'

import './Header.scss'
import './Presentation.css'

import AOS from 'aos';
import "aos/dist/aos.css";
const paragraph = [
    {
        title: "TRILLIONAIRE THUGS",
        content: <p className="" style={{maxWidth: '500px', paddingTop: '20px', fontFamily: 'Din', fontSize: '20px'}}>Trillionaire Thugs is a <b style={{color: "#f7c02e", fontFamily: "Din"}}>collection of 7,777 NFTs</b>, each representing a Thug as a digitized piece of 3D art. With more than <b style={{color: "#f7c02e", fontFamily: "Din"}}>350 traits in 3D</b>, our collection will be one of the most diverse and detailed. Our goal is to bring the hip hop and urban scene to the NFT market and build a <b style={{color: "#f7c02e", fontFamily: "Din"}}>community</b> around the <b style={{color: "#f7c02e", fontFamily: "Din"}}>hype of this universe</b>. The models to follow are no longer in suits, but in sneakers, their crypto wallets often being more loaded! Our characters who have been digitized by talented artists to give them life in the Metaverse. By joining the community, you may be making the best investment of your life, in this new financial era where being cool and rich is more compatible than ever!"</p>,
    },
    {
        title: "BE PART OF THE GANG!",
        content: <p className="" style={{maxWidth: '500px', paddingTop: '20px', fontFamily: 'Din', fontSize: '20px'}}>Your NFT is both an <b style={{color: "#f7c02e", fontFamily: "Din"}}>investment</b> and a key that can open the doors to a <b style={{color: "#f7c02e", fontFamily: "Din"}}>premium community</b>, which is called <b style={{color: "#f7c02e", fontFamily: "Din"}}>Trillionaire Thugs’ Gang</b> and that will give you access to many exciting rewards and awesome events! Owning a Trillionaire Thug NFT means that, more than just owning a piece of art, you are <b style={{color: "#f7c02e", fontFamily: "Din"}}>part of a gang</b> which grants you access to various benefits that will grow over time such as Merch and a free NFT collection drop.
        If you want to stay up to date and know everything about the <b style={{color: "#f7c02e", fontFamily: "Din"}}>upcoming events</b> that will happen for the community as well as the <b style={{color: "#f7c02e", fontFamily: "Din"}}>progress of our different projects</b>, follow us on our <b style={{color: "#f7c02e", fontFamily: "Din"}}>social media</b>, where you will be able to check our <b style={{color: "#f7c02e", fontFamily: "Din"}}>roadmap</b>!</p>,
    },
    {
        title: "RARE & UNIQUES",
        content: <p className="" style={{maxWidth: '500px', paddingTop: '20px', fontFamily: 'Din', fontSize: '20px'}}>Have you ever seen a gang without a boss? In any gang, you get respect and the benefits that go with it, by climbing the ladder. On mint day, you have a direct chance to <b style={{color: "#f7c02e", fontFamily: "Din"}}>become a Gold member without having to start from the bottom</b>. On mint day, you will have 5% chance to mint a Golden Thug, as <b style={{color: "#f7c02e", fontFamily: "Din"}}>390 pieces are randomly Golden</b>! Their owners will receive additional benefits when the NFTs are connected to the Metaverse. Just by holding you will get a reward from the crew! In addition to that, 10 Legendary NFTs that will make reference to the crypto world will complete the collection.</p>,
    },
    {
        title: "PLAY 2 EARN GAME",
        content: <p className="" style={{maxWidth: '500px', paddingTop: '20px', fontFamily: 'Din', fontSize: '20px'}}>The development team is currently working on a <b style={{color: "#f7c02e", fontFamily: "Din"}}>game Play2Earn</b> in collaboration with an <b style={{color: "#f7c02e", fontFamily: "Din"}}>international publisher and a L2 ETH blockchain</b>. 
Trillionaire Thugs will create its <b style={{color: "#f7c02e", fontFamily: "Din"}}>own Token</b> this year and the holders of the initial collection will get an <b style={{color: "#f7c02e", fontFamily: "Din"}}>airdrop of this coin</b> (depending on the duration of their hold and the number of holders).
This Play2Earn game will be accessible to everyone and will allow players to <b style={{color: "#f7c02e", fontFamily: "Din"}}>enhance their gaming experience</b> thanks to their <b style={{color: "#f7c02e", fontFamily: "Din"}}>NFTs</b>. On top of that, the gangsta thing is that players will be able to <b style={{color: "#f7c02e", fontFamily: "Din"}}>earn coins while playing</b>! 
Holders of the first collection will get a <b style={{color: "#f7c02e", fontFamily: "Din"}}>free airdrop</b> from a NFT collection associated with the game, on the new blockchain. 
We expect the game to be released for the <b style={{color: "#f7c02e", fontFamily: "Din"}}>end of 2022</b>, meanwhile be ready for big announcements soon!</p>,
    }
]

const PresentationMobile = (props) => {
    useEffect(() => {
        AOS.init({ duration: 1000, once: false });
    }, [])
    return (
        <section id={props.section}>
            <div style={{paddingTop: '10px'}}>
                <div class="bar-title2">
                    <p class="text-title inside" style={{color: "rgb(212, 197, 168)"}}>{paragraph[props.int].title}</p>
                </div>
                <div class="bar-mid">
                    <div class="toppixel"></div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <p class="text-paragraph2" style={{paddingTop: "20px", fontFamily: "Din", textAlign: "justify"}}>{paragraph[props.int].content}</p>
                            </div>
                        </div>
                    </div>
                    <div class="botpixel"></div>
                    <div style={{width: "100%", height: "100%", left: "50%"}}></div>
                </div>
                {props.int == 0 ? 
                    <div style={{width: '100%', height: '375px', left: '50%'}}>
                        <div className="card-to-rotate"></div>
                        <div className="card-to-rotate2"></div>
                    </div>
                    :
                    <div style={{textAlign: 'center', marginTop: '20px', marginBottom: '10px'}} data-aos="fade-right">
                        <img src={props.pic}  width="90%" height="auto" style={{margin: "auto",borderRadius:"10px"}}/>
                    </div>
                }
            </div>
        </section>
    )
}

export default PresentationMobile