import React, { useEffect } from 'react'
import "./Header.scss"

import Faq from "react-faq-component";

import AOS from 'aos';
import "aos/dist/aos.css";

const data = {
    rows: [
        {
            title: <h2 style={{fontFamily: "Din", fontSize: "22px"}}>What is Trillionaire Thugs?</h2>,
            content: <p style={{fontFamily: "Din"}}>By creating Trillionaire Thugs, our goal is to bring the hip hop and urban scene to the NFTs market. Our characters are thugs that evolve in a hype Universe and that have been digitized by talented artists to bring them alive in the Metaverse.</p>,
        },
        
           
        {
            title: <h2 style={{fontFamily: "Din", fontSize: "22px"}}>Where can I buy Trillionaire Thugs NFTs?</h2>,
            content: <p style={{fontFamily: "Din"}}> You can buy Thugs in our collection on Open Sea from their owners but not all the Thugs are for sale! </p>,
        },
        {
            title: <h2 style={{fontFamily: "Din", fontSize: "22px"}}>What is the supply for this collection?</h2>,
            content: <p style={{fontFamily: "Din"}}>7,777 Thugs including 390 Gold Thugs and 10 Uniques Thugs.</p>,
        },
        {
            title: <h2 style={{fontFamily: "Din", fontSize: "22px"}}>On which blockchain is Trillionaire Thugs?</h2>,
            content: <p style={{fontFamily: "Din"}}>Trillionaire Thugs is using the Ethereum network and the collection is hosted on Opensea. However, the play2earn game will be using a Layer 2 to avoid the gas fees that are too high with ETH.</p>,
        },
        
        {
            title: <h2 style={{fontFamily: "Din", fontSize: "22px"}}>How can I be part of the community?</h2>,
            content: <p style={{fontFamily: "Din"}}>To be part of the community, you need to hold at least one piece of the collection. It will give you access to many exciting rewards and awesome events.</p>,
        },
        {
            title: <h2 style={{fontFamily: "Din", fontSize: "22px"}}> When will the Play2Earn game be released?</h2>,
            content: <p style={{fontFamily: "Din"}}>The game is currently in production and will be released in Q4 2022. At the same time the Trillionaire Thugs token will be deployed and people will earn coins by playing the game and making money!</p>,
        },
        {
            title: <h2 style={{fontFamily: "Din", fontSize: "22px"}}>Do I own the intellectual property rights of Trillionaire Thugs?</h2>,
            content: <p style={{fontFamily: "Din"}}>By buying your NFT, on the first or second market, you will own all intellectual property rights on your NFT.</p>,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "white",
    rowTitleColor: "white",
    titleTextSize: "36px",
    // rowContentPaddingLeft: '40px',
    rowContentColor: 'white',
    arrowColor: "white",
    rowTitleTextSize: '30px',
    rowContentTextSize: '18px'
};

const FaqSection = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, [])
    return (
        <section id="faq">
        {
        window.innerWidth > 900 ?
            <div data-aos="fade-up" data-aos-offset="200">
                <div className="bar-mid-top"></div>
                <div className="bar-title2">
                        <p className="text-title inside" style={{color: '#d4c5a8'}}>FAQ</p>
                    </div>
                <div className="bar-mid">
                    <div style={{display: "flex", maxWidth: '1150px'}}>
                        <div className="decimal-text inside"></div>
                        <Faq data={data} styles={styles}/>
                    </div>
                </div>
                <div className="bar-bottom"></div>
            </div>
            : 
            <>
            {/* <div className="bar-mid-top"></div> */}
                <div className="bar-title2">
                    <p className="text-title inside" style={{color: '#d4c5a8'}}>FAQ</p>
                </div>
                {/* <div className="bar-mid"> */}
                    <div style={{display: "flex", padding: "0 30px"}}>
                        {/* <div className="decimal-text inside"></div> */}
                        <Faq data={data} styles={styles}/>
                    </div>
                {/* </div> */}
                {/* <div className="bar-bottom"></div> */}
            </>
        }
        </section>
    )
}

export default FaqSection
