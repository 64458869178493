import React, {useEffect, useState} from 'react';
import './Header.scss';
import './RoadMap.scss';

import AOS from 'aos';
import "aos/dist/aos.css";

const RoadMap = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        let totalHeight = document.body.scrollHeight - window.innerHeight;
        let position = window.pageYOffset;
        if (window.innerHeight  < 1100) {
            if (position < 3200) return setScrollPosition(0);
            if (position > 4100) return setScrollPosition(86);
            let progressHeight = ((position - 3200) / 1100) * 100;
            setScrollPosition(progressHeight);
        } else {
            if (position < 2900) return setScrollPosition(0);
            if (position > 4000) return setScrollPosition(86);
            let progressHeight = ((position - 2900) / 1300) * 100;
            setScrollPosition(progressHeight);
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        AOS.init({ duration: 1000, once: true, offset: 300 });
    }, [])
    return (
        <section id="roadmap">
            {window.innerWidth < 992 ?
                <>
                    <div className="bar-title2" style={{marginTop: "15px"}}>
                        <p className="text-title inside" style={{color: '#d4c5a8'}}>ROADMAP</p>
                    </div>
                <div style={{
                    padding: "0px 40px 0px 40px"
                }}>

                    <div class="roadmap--container col-xl-10 col-12">
                        <div class="roadmap--line"></div>
                        <div class="roadmap--wrapper">
                            <div class="roadmap--item">
                                <h2 class="h2 roadmap--item-title" data-aos="fade-up">Phase 1</h2>
                                <span class="roadmap--point">
                                    <span class="roadmap--point-inside"></span>
                                </span>
                                    <h4 class="phase1" data-aos="fade-up"><b class=" h5 title ">TAKE OFF</b><br/></h4>
                                    <span class="new-line firstphase" data-aos="fade-up">
                                    <ul>
                                        <li>Launch of our Discord with over 150k members & Website</li>
                                        <li>Partner with Culture Kings to drop our first merch to the public</li>
                                        <li>International marketing campaign with Billboards worldwide (Space X and Time Square)</li>
                                        <li>Get Influencers, Professional Athletes, Recording Artists, Musicians, Producers & Successful Business People, with social media reach of over 100M+, onboard organically</li>
                                        <li>Launch the 7,777 NFTs collection at the end of January with reveal early February</li>
                                        <li>Start Sunday AMA's with high profile TThugs owners</li>
                                        </ul>
                                    </span>
                                </div>
                                <div class="roadmap--item">
                                    <h2 class="h2 roadmap--item-title" data-aos="fade-up">Phase 2</h2>
                                    <span class="roadmap--point"></span>

                                    <h4 class="phase2" data-aos="fade-up"><b class=" h5 title ">Brand & Utility Expansion</b><br/></h4><span class="new-line secondphase" data-aos="fade-up">
                                       <ul>
                                        <li>Take a 30-day SNAPSHOT to find our ‘OG TThugs’ who have MINTED & HODL’d</li>
                                        <li>Launch ‘OG TThugs’ exclusive badge for Discord</li>
                                        <li>Announce the exclusive real life tangible assets for ‘OG TThugs’ + Long-term holders (3 months delisted & Hodlers) - These will be LIMITED 1 of 1 encased Trillionaire Thug NFT Cards which link to the digital form of our NFT’s</li>
                                        <li>Announce the exclusive holders merchandise for ‘OG TThugs’ and Long-term holders, this one time only exclusive Hodlers merchandise will include a hoodie, shirt, beanie and more</li>
                                        <li>Open merchandise & tangible asset pre-sale for ‘OG TThugs’ and Long-term holders</li>
                                        <li>Announce partnership with a global gaming publisher + Blockchain</li>
                                        <li>Announce more details on the Play to Earn game</li>
                                        <li>Launch Weekly AMA’s with holders and community members with TTHUGS Founders, The Team and special guests & influencers</li>
                                        <li>Declare our 10 TThug Commandments</li>
                                        <li>Exclusive Utility & Value for Gold TThug NFT holders & UNIQUE holders</li>
                                        </ul>
                                    </span>
                                </div>
                                <div class="roadmap--item">
                                    <h2 class="h2 roadmap--item-title " data-aos="fade-up">Phase 3</h2>
                                    <span class="roadmap--point"></span>
                                    
                                    <h4 class="phase3" data-aos="fade-up"><b class=" h5 title ">Access to TThug Team and Army</b><br/></h4><span class="new-line secondphase" data-aos="fade-up">
                                       <ul>
                                        <li>Holders will be granted further access to The Team & other successful people within the TThugs network</li>
                                        <li>Invite ALL holders to attend events in Auckland, NZ, Gold Coast & Sydney, AUS, Dubai, UAE and Miami, USA. This will give all of our TThugs Army a chance to celebrate & meet in real life, putting faces to discord names/voices</li>
                                        <li>Exclusive VIP treatment at events for “UNIQUE HOLDERS”, including sitting with The Founders & Team</li>
                                        <li>Release Teaser on P2E game</li>
                                        </ul>
                                    </span>
                                </div>
                                <div class="roadmap--item">
                                    <h2 class="h2 roadmap--item-title"  data-aos="fade-up">Phase 4</h2>
                                    <span class="roadmap--point"></span>
                                    <h4 class="phase4" data-aos="fade-up"><b class=" h5 title ">Play 2 Earn Game</b><br/></h4><span class="new-line secondphase" data-aos="fade-up">
                                        <ul>
                                        <li>Confirmation of gaming partnership to community & public</li>
                                        <li>Begin marketing campaign for the P2E game</li>
                                        <li>Release Whitepaper and Tokenomics for P2E game</li>
                                        <li>Launch our own Trillionaire Thugs Token</li>
                                        <li>Distribution of tokens for long term holders</li>
                                        <li>NFT airdrop for the 'OG TThugs'</li>
                                        <li>Roll out Global marketing campaign for P2E game with gaming publisher</li>
                                        <li>Global Launch of Play 2 Earn Game within Q4 2022 - Q1 2023</li>
                                        </ul>
                                    </span>
                                </div>
                                
                               
                            </div>
                        </div>
                </div>
            </>
            : 
            <>
                <div className="bar-mid-top"></div>
                <div className="bar-title2">
                    <p className="text-title inside" style={{color: '#d4c5a8'}}>ROADMAP</p>
                </div>
                <div className="bar-mid">
                    <div style={{display: 'flex', flexDirection: 'row', maxWidth: '1150px'}}>
                        <div className="decimal-text inside"></div>
                        <div style={{width: '15vw'}}>
                            <p data-aos="fade-right" className="phase-count" style={{paddingTop: '84px', textAlign: "center"}}>PHASE 1</p>
                            <p data-aos="fade-right" style={{
                                width: "60%",
                                textAlign: "center",
                                margin: "auto",
                                color: "rgb(98, 97, 97)"
                            }}>TAKE OFF</p>
                            <p data-aos="fade-right" className="phase-count" style={{paddingTop: '264px', textAlign: "center"}}>PHASE 2</p>
                            <p data-aos="fade-right" style={{
                                width: "65%",
                                textAlign: "center",
                                margin: "auto",
                                textTransform: "uppercase",
                                color: "rgb(98, 97, 97)"
                            }}>Brand & Utility Expansion</p>
                            <p data-aos="fade-right" className="phase-count" style={{paddingTop: '470px', textAlign: "center"}}>PHASE 3</p>
                            <p data-aos="fade-right" style={{
                                width: "65%",
                                textAlign: "center",
                                margin: "auto",
                                textTransform: "uppercase",
                                color: "rgb(98, 97, 97)"
                            }}>Access to TThug Team and Army</p>
                            <p data-aos="fade-right" className="phase-count" style={{paddingTop: '224px', textAlign: "center"}}>PHASE 4</p>
                            <p data-aos="fade-right" style={{
                                width: "65%",
                                textAlign: "center",
                                margin: "auto",
                                textTransform: "uppercase",
                                color: "rgb(98, 97, 97)"
                            }}>Play 2 Earn Game</p>
                            
                            
                        </div>
                        <div className="roadmap--container" style={{display: 'inline-flex'}}>
                            <div class="roadmap--line-empty" ></div>
                            <div class="roadmap--line" style={{height: 93.5 + "%"}}></div>
                            <div>
                                <span class="roadmap--point">
                                    <span class="roadmap--point-inside"></span>
                                </span>
                                <div data-aos="fade-left" className="carton c1">
                                <p className="contenu-description firstphase">
                                <ul >
                                    <li>Launch of our Discord with over 150k members & Website</li>
                                    <li>Partner with Culture Kings to drop our first merch to the public</li>
                                    <li>International marketing campaign with Billboards worldwide (Space X and Time Square)</li>
                                    <li>Get Influencers, Professional Athletes, Recording Artists, Musicians, Producers & Successful Business People, with social media reach of over 100M+, onboard organically</li>
                                    <li>Launch the 7,777 NFTs collection at the end of January with reveal early February</li>
                                    <li>Start Sunday AMA's with high profile TThugs owners</li>
                                    </ul>
                                    </p>
                                </div>
                                <span class="roadmap--point pad-point">
                                    <span class="roadmap--point-inside"></span>
                                </span>
                                <div data-aos="fade-left"  className="carton c2">

                                    <p className="contenu-description secondphase">
                                    <ul>
                                    <li>Take a 30-day SNAPSHOT to find our ‘OG TThugs’ who have MINTED & HODL’d</li>
                                    <li>Launch ‘OG TThugs’ exclusive badge for Discord</li>
                                    <li>Announce the exclusive real life tangible assets for ‘OG TThugs’ + Long-term holders (3 months delisted & Hodlers) - These will be LIMITED 1 of 1 encased Trillionaire Thug NFT Cards which link to the digital form of our NFT’s</li>
                                    <li>Announce the exclusive holders merchandise for ‘OG TThugs’ and Long-term holders, this one time only exclusive Hodlers merchandise will include a hoodie, shirt, beanie and more</li>
                                    <li>Open merchandise & tangible asset pre-sale for ‘OG TThugs’ and Long-term holders</li>
                                    <li>Announce partnership with a global gaming publisher + Blockchain</li>
                                    <li>Announce more details on the Play to Earn game</li>
                                    <li>Launch Weekly AMA’s with holders and community members with TTHUGS Founders, The Team and special guests & influencers</li>
                                    <li>Declare our 10 TThug Commandments</li>
                                    <li>Exclusive Utility & Value for Gold TThug NFT holders & UNIQUE holders</li>
                                    </ul>
                                    </p>
                               
                                </div>
                                <span class="roadmap--point pad-point">
                                    <span class="roadmap--point-inside"></span>
                                </span>
                                <div data-aos="fade-left"  className="carton c3">
                                    <p className="contenu-description secondphase">
                                    <ul>
                                    <li>Holders will be granted further access to The Team & other successful people within the TThugs network</li>
                                    <li>Invite ALL holders to attend events in Auckland, NZ, Gold Coast & Sydney, AUS, Dubai, UAE and Miami, USA. This will give all of our TThugs Army a chance to celebrate & meet in real life, putting faces to discord names/voices</li>
                                    <li>Exclusive VIP treatment at events for “UNIQUE HOLDERS”, including sitting with The Founders & Team</li>
                                    <li>Release Teaser on P2E game</li>
                                    </ul>
                                    </p>
                                </div>
                                <span class="roadmap--point pad-point">
                                    <span class="roadmap--point-inside"></span>
                                </span>
                                <div data-aos="fade-left"  className="carton c4">
                                    <p className="contenu-description secondphase">
                                    <ul>
                                    <li>Confirmation of gaming partnership to community & public</li>
                                    <li>Begin marketing campaign for the P2E game</li>
                                    <li>Release Whitepaper and Tokenomics for P2E game</li>
                                    <li>Launch our own Trillionaire Thugs Token</li>
                                    <li>Distribution of tokens for long term holders</li>
                                    <li>NFT airdrop for the 'OG TThugs'</li>
                                    <li>Roll out Global marketing campaign for P2E game with gaming publisher</li>
                                    <li> Global Launch of Play 2 Earn Game within Q4 2022 - Q1 2023</li>
                                    </ul>
                                    </p>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bar-bottom"></div>
            </>
        }
        </section>
    )
}

export default RoadMap
